export const configuratorComponentsTranslation = {
  en: {
    STONE_RING: {
      "Tiger's Eye": "Tiger's Eye",
      "Goldstone Green": "Goldstone Green",
      Printstone: "Printstone",
      "Natural Agate Brown": "Natural Agate Brown",
      "Mahagony Obsidian": "Mahagony Obsidian",
      "Blue Lace": "Blue Lace",
      "Natural Agate White": "Natural Agate White",
      "Natural Agate Yellow": "Natural Agate Yellow",
      Carnelian: "Carnelian",
      "Lapis Lazuli": "Lapis Lazuli",
      "Landscape Jasper": "Landscape Jasper",
      "Picasso Jasper": "Picasso Jasper",
      "Kalahari Jasper": "Kalahari Jasper",
      Dumortierite: "Dumortierite",
      "Brown Agate": "Brown Agate",
      Howlith: "Howlith",
      "Jasper Yellow": "Jasper Yellow",
      "Quartz Blue": "Quartz Blue",
      Rhodonite: "Rhodonite",
      Verdite: "Verdite",
    },
    CASE: {
      white: "WHITE GOLD",
      rose: "ROSE GOLD",
      "white with diamonds": "WHITE GOLD with diamonds",
      "rose with diamonds": "ROSE GOLD with diamonds",
    },
    DIAL: {
      "black gold": "black gold",
      "black silver": "black silver",
      "white gold": "white gold",
      "white blue": "white blue",
    },
    HANDS: {
      black: "BLACK",
      white: "WHITE",
    },
    STRAP: {
      brown: "BROWN",
      black: "BLACK",
      blue: "BLUE",
      white: "WHITE",
    },
  },
  ru: {
    STONE_RING: {
      "Tiger's Eye": "Тигровая яшма",
      "Goldstone Green": "Зелёный Авантюрин",
      Printstone: "Красная яшма",
      "Natural Agate Brown": "Коричневый агат ",
      "Mahagony Obsidian": "Махагоновый Обсидиан",
      "Blue Lace": "Голубой Агат",
      "Natural Agate White": "Белый Агат",
      "Natural Agate Yellow": "Желтый Агат",
      Carnelian: "Сердолик",
      "Lapis Lazuli": "Лазурит",
      "Landscape Jasper": "Пейзажная Яшма",
      "Picasso Jasper": "Яшма Графика",
      "Kalahari Jasper": "Песочная яшма",
      Dumortierite: "Дюмортьерит",
      "Brown Agate": "Коричневый Агат",
      Howlith: "Говлит",
      "Jasper Yellow": "Желтая яшма",
      "Quartz Blue": "Голубой кварц",
      Rhodonite: "Родонит",
      Verdite: "Вердит",
    },
    CASE: {
      white: "Белое Золото",
      rose: "Розовое Золото",
      "white with diamonds": "Белое Золото с бриллиантами",
      "rose with diamonds": "Розовое Золото с бриллиантами",
    },
    DIAL: {
      "black gold": "Черный золото",
      "black silver": "Черный серебро",
      "white gold": "Белый золото",
      "white blue": "Белый синий",
    },
    HANDS: {
      black: "BLACK",
      white: "WHITE",
    },
    STRAP: {
      brown: "Коричневый",
      black: "Черный",
      blue: "Синий",
      white: "Белый",
    },
  },
};
